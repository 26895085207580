.HeroContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 80vh;
}



.Highlight {
    color: rgb(224, 230, 247);
    
    font-size: 38px;
}



.HeaderText{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 0px;
    max-width: 90vw;
    
    
    
    
}

.HeaderText h1, h3{
    padding: 0px;
    margin: 0;
    color: white;
    
}

.HeaderText h1{
    text-align: center
    ;
    font-size: 52px;
    padding: 40px 0px;
    margin: 0;

    
    

}
.HeaderText h3{
    margin: 5px 0px;
    color: rgb(221, 247, 247);
    font-size: 26px;
    max-width: 90vw;
    
    text-align: center;
    overflow: hidden;
    height: 100%;
}
.Links {
    display: flex;
    justify-content: space-around;
    margin: 10px;
    
}



.companyname h1{ 
    font-size: 82px;
    padding: 10px 0px;
    color: white;
    font-family: 'Poppins', sans-serif;
    
}

video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    -webkit-filter: blur(2px);
    -webkit-filter: saturate(40%);
    -webkit-filter: brightness(70%);
    
    
}








@keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(10px);
    }
  }



.Herobutton{
    display: flex;
    align-self: center;
    font-size: 22px;
    font-weight: bold;
    color: #3264aa;
    letter-spacing: 1px;
    background: linear-gradient(to bottom, #e1f1ff 0%, #c0dffe 100%);
    border: none;
    border-radius: 16px;
    padding: 10px 20px;
    cursor: pointer;
    transition: ease-in-out 0.3s;
    text-shadow: 1px 2px 2px rgba(0,0,0,0.2);
    box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
    margin-top: 50px;
    
}

.Herobutton:hover{
    font-size: 22px;
    font-weight: bold;
    color: #5d81b5;
    letter-spacing: 1px;
    background: #ffffff;
    border: none;
    border-radius: 16px;
    padding: 10px 20px;
    cursor: pointer;
    transition: ease-in-out 0.3s;
    text-shadow: none;
    
}



.Popup{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: #fff;
    padding: 20px 40px;
    border-radius: 12px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }

.Listcontainer{
    display: flex;
    
} 


.Antikörperbild{
    max-width: 400px;
    max-height: 150px;
}

.PopupInner{
    display: flex;
    flex-direction: column;
    
    
}

.PopupInner ul{
    display: flex;
    font-size: 18px;
    flex-direction: column;
    align-items: flex-start;

}




.CloseButton{
    font-size: 18px;
    font-weight: bold;
    
    letter-spacing: 1px;
    background-color: rgba(163, 209, 248, 0.842);
    border: none;
    border-radius: 16px;
    padding: 10px 20px;
    cursor: pointer;
    transition: ease-in-out 0.3s;
    box-shadow: 1px 1px 1px  rgba(24, 24, 24, 0.137);
    
}


@media only screen and (max-width: 1024px) {

    .HeroContainer{
        padding: 0px;
    }

    .HeroSection{
        height: 80vh;
        overflow: hidden;
    }
  

    .HeaderText{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 0;
        width: 100%;
    }

    .HeaderText h1 {
        text-align: center;
        font-size: 50px;
        
    }

    .HeaderText h3{
        font-size: 30px;
        font-weight: 200;
        line-height: 105%;
    }
}

@media only screen and (max-width: 750px) {
    .HeroContainer{
        padding: 0px;
        overflow: hidden;
    }

    .HeroSection{
        height: 100vh;
        overflow: hidden;
    }
  

    .HeaderText{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 0;
        width: 100%;
        padding-top: 40px;
    }

    .HeaderText h1 {
        text-align: center;
        font-size: 35px;
        padding: 10px
        
    }

    .HeaderText h3{
        font-size: 22px;
        font-weight: 200;
        line-height: 105%;
    }
}