@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

* {
	padding: 0;
	margin: 0;
    font-family: "Questrial";
    
}

body::-webkit-scrollbar{
    display: none;
  }
  

  .navcontainer{
    display: flex;
    justify-content: space-between;
    width: 60%;
    height: 20vh;
  }

nav{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    letter-spacing: 1.1px;
    justify-content: flex-end;
    
    align-items: center;
    
    font-weight: bold;
    
}




.Logo{
    max-width: 60px;
    cursor: pointer;
   
 
}

.lngbutton{
    background-color: rgba(0, 147, 135, 1);
    color: white;
    border: none;
    padding: 5px;
    margin: 5px;
    
    
    
}

.Navbutton{
    font-size: 15px;
    font-weight: bold;
    
    letter-spacing: 1px;
    background-color: rgba(0, 147, 135, 1);
    border: none;
    color: white;
    padding: 10px 15px;
    cursor: pointer;
    transition: ease-in-out 0.3s;
    box-shadow: 1px 1px 1px  rgba(24, 24, 24, 0.137);
    text-decoration: none;
    
    
}

.Navbutton a{
    color: white;
    font-family: "Questrial";
    text-decoration: none;
    
}


.lngchange{
    display: flex;
    justify-content: space-around;
    
    align-items: center;
}

.Navlogo{
    opacity: 0;
   
}

.Navlink{
    display: flex;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
    transition: ease-in-out 0.4s;
    
    font-family: "Questrial";
    
    
}





header {
    overflow: hidden;
    
    position: fixed;
    width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 10vh;
	padding: 0 2rem;
	background-color: rgb(255, 255, 255);
    
	
    z-index: 54;
    
}

.Navlink{
    font-size: 26px;
    padding: 10px 0px;
    font-size: 16px;

}

.Navlink:hover {
	color: rgb(32, 197, 183);
    cursor: pointer;
    transition: ease-in-out 0.3s;
}

.nav-btn {
    display: flex;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
    
	
	opacity: 0;
	font-size: 1.8rem;
    overflow:hidden;
}

header div,
nav {
	display: flex;
	align-items: center;
    overflow:hidden;
    
}

@media only screen and (max-width: 1024px) {
    

	.nav-btn {
		visibility: visible;
		opacity: 1;
        padding-right: 50px;
        
	}

    .lngchange{
        display: flex;
        justify-content: center;
        padding: 0 100px;
    }

    .Navlogo{
        opacity: 1;
        max-width: 100px
    }

	header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100vw;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: white;
        
		transition: 1s;
        z-index: 999;
       
	}

    .navcontainer{
        display: flex;
        flex-direction: column;
        height: 50%;
        
    }

    .Navlink{
        font-size: 22px;
    }

	header .responsive_nav {
		transform: translateY(100vh);
        display: flex;
        width: 100%;

	}

	nav .nav-close-btn {
		position: absolute;
		top: 10rem;
        left: 5rem;
		
	}

	
}



@media only screen and (max-width: 750px) {
    

	.nav-btn {
		visibility: visible;
		opacity: 1;
        padding-right: 50px;
        
	}

    .lngchange{
        display: flex;
        justify-content: center;
        padding: 0 50px;
    }

    .Navlogo{
        opacity: 1;
        max-width: 100px
    }

	header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100vw;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: white;
        
		transition: 1s;
        z-index: 999;
       
	}

    .navcontainer{
        display: flex;
        flex-direction: column;
        height: 50%;
        
    }

    .Navlink{
        font-size: 22px;
    }

	header .responsive_nav {
		transform: translateY(100vh);
        display: flex;
        width: 100%;

	}

	nav .nav-close-btn {
		position: absolute;
		top: 10rem;
        left: 5rem;
		
	}

	
}
