.Textcontainer{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    
    padding: 0;
     margin: 0;
    overflow: hidden;
    
   
   
   
}




.Textheader{
    display: flex;
    justify-content: center;
    font-size: 20px;
    
    color: #3264aa;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    
}

.Textheader h2{
    display: flex;
    justify-content: center;
    overflow: hidden;
    font-size: 26px;
    padding-bottom: 40px;
    color: #0063a0;
    font-family: 'Poppins', sans-serif
}



.Arrow{
    max-width: 30px;
    padding-left: 20px;
    cursor: pointer;
    animation-name: bounce ;
    animation-duration: 4s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}


.Arrow2{
    max-width: 40px;
    transform: rotate(180deg);
    cursor: pointer;
}


@keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(5px);
    }
  }

.Literatur{
    display: flex;
    
    flex-direction: column;
    
    width: 100vw;
    
    
    text-shadow: 1px 1px 8px rgba(247, 247, 247, 0.815);;
    
    
}


.Literaturbutton {
    font-size: 15px;
    font-weight: bold;
    
    letter-spacing: 1px;
    background-color: rgba(0, 147, 135, 1);
    border: none;
    color: white;
    padding: 10px 15px;
    cursor: pointer;
    transition: ease-in-out 0.3s;
    box-shadow: 1px 1px 1px  rgba(24, 24, 24, 0.137);
    text-decoration: none;
}

.Literaturbutton:hover {
    background-color: rgb(32, 197, 183)
}

.Literaturcontainer a{
    padding: 0;
    
    cursor: pointer;
    font-weight: 600;
    
}



.Literaturheader{
    display: flex;
    letter-spacing: 3.2px;
    justify-content:center;
    padding: 10px 20px; 
    
    background-color: white;
   padding: 40px 0px;
    
   color: #0063a0;
}

.Überschrift{
    display: flex;
    justify-content:center;
    padding: 10px 20px; 
    margin: 0px 0px;
    font-size: 26px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif
    
}

.Literaturtext{
    display: flex;
    flex-direction: column;
    max-height: 0;
    transition:  0.76s ease-in-out;
  
    
}

.Literaturtext.show{
    max-height: 2000px;
}




.Text, .Text2 {
    display: flex;
    flex-direction: column;

    padding: 20px 200px;
    height: 100%;
    background-color: white;
    font-size: 20px;
    font-family: "Questrial", sans-serif;
    color: #0063a0;
    line-height: 140%;

    
    
    
}

.Text a{
    color: rgba(0, 147, 135, 1);
    font-size: 15px;
}


.Schliessen{
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    padding: 20px 0px;
    overflow: hidden;
    color: black;
    background: linear-gradient(to top, #b1dafd6e 0%, #c0dffe75 100%);    
}

.highlight{
        font-weight: bold;
        color: #3264aa;
        font-family: "Questrial", sans-serif;
        font-size: 20px;
        text-shadow: none;
        padding: 10;
        margin: 0;
}

@media only screen and (max-width: 1024px){



    .Literaturheader{
        display: flex;
        letter-spacing: 3.2px;
        justify-content:center;
        padding: 10px 10px; 
        margin: 0px 0px;
        font-size: 38px;
        font-weight: 600;
        background: linear-gradient(to bottom, #e1f1ff 0%, #c0dffe 100%);
        color: #3264aa;
    
    }

    .Arrow{
        max-width: 30px;
        padding-left: 20px;
        cursor: pointer;
        animation-name: bounce ;
        animation-duration: 4s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }

    .Patente{
        display: flex;
        justify-content: space-around;
        background: linear-gradient(to top, #e1f1ff 0%, #c0dffe 100%);
        width: 100%;
        height: 10vh;
        
        
    }

    .Patentbutton{
        overflow-y: hidden;
        font-size: 24px;
        font-weight: bold;
        color: #3264aa;
        letter-spacing: 1px;
        background: linear-gradient(to bottom, #e1f1ff 0%, #c0dffe 100%);
        border: none;
        border-radius: 8px;
        padding: 5px 20px;
        margin: 15px 0px;
        cursor: pointer;
        transition: ease-in-out 0.3s;
        text-shadow: 1px 2px 2px rgba(0,0,0,0.2);
        box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
    }

    .Text  {
        height: 100%;
        font-size: 20px;
        padding: 20px 80px;
        
    }
    
    .Textheader{
        
        padding: 40px 0px;
    }

    .Textheader h2{
        font-size: 24px;
    }
    
.Literaturtext.show{
    max-height: 2000px;
}

}



@media only screen and (max-width: 750px) {
    


    .Literaturheader{
        display: flex;
        letter-spacing: 3.2px;
        justify-content:center;
        padding: 20px 10px; 
        margin: 0px 0px;
        font-size: 38px;
        font-weight: 600;
        background: linear-gradient(to bottom, #e1f1ff 0%, #c0dffe 100%);
        color: #3264aa;
    
    }

    .Arrow{
        max-width: 30px;
        padding-left: 20px;
        cursor: pointer;
        animation-name: bounce ;
        animation-duration: 4s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }

    .Patente{
        display: flex;
        justify-content: space-around;
        background: linear-gradient(to top, #e1f1ff 0%, #c0dffe 100%);
        width: 100%;
        height: 10vh;
        
        
    }

    .Patentbutton{
        overflow-y: hidden;
        font-size: 24px;
        font-weight: bold;
        color: #3264aa;
        letter-spacing: 1px;
        background: linear-gradient(to bottom, #e1f1ff 0%, #c0dffe 100%);
        border: none;
        border-radius: 8px;
        padding: 5px 20px;
        margin: 15px 0px;
        cursor: pointer;
        transition: ease-in-out 0.3s;
        text-shadow: 1px 2px 2px rgba(0,0,0,0.2);
        box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
    }

    .Text{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 30px;
    }

    .Text p {
        height: 100%;
        font-size: 20px;
        padding: 0px 10px;
        
        
    }
    
    .Textheader{
        
        padding: 60px 0px;
        overflow: hidden;
    }

    .Textheader h2{
        font-size: 24px;
    }
    
.Literaturtext.show{
    max-height: 2000px;
}
}