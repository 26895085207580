*{
  max-width: 100vw;
  overflow-x: hidden;
  
  font-family: "Questrial" , sans-serif;
}

/* Set the width and height of the scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track (the area where the scrollbar sits) */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Handle (the part you drag with the mouse) */
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 147, 135, 1);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 147, 135, 0.5);
  
}


.AppContainer{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 0.5fr, repeat(16, 1fr);
    
    max-width: 100vw;
    height: 100%;
  }

.Navbar{

    grid-column: 1/-1;
    grid-row: 1/1;
    z-index: 99;
    top: 0;
    height: 10vh;
    box-shadow: 1px 1px 2px rgb(221, 217, 217);

}

.HeroSection{
    grid-row: 2/4;
    grid-column: 1/-1;
    height: 100%;
    width: 100vw;
   
  }
  

.Description{
  grid-row: 6/8;
  grid-column: 1/-1;
  height: 100%;
  width: 100%;
  background-color: black;
}


.CTA{
    display: flex;
    align-items: center;
    justify-content: center;
    grid-row: 4/6;
    height: 100%;
    width: 100vw;
    background-color: aliceblue;
    height: 15vh;
  }


  .Literatur{
    display: flex;
    
    border-bottom: 2px solid  aliceblue;
    
    grid-row: 11/12;
    grid-column: 1/-1;
  }


  .About{
    display: flex;
    grid-column: 1/-1;
    grid-row: 8/11;
    margin-bottom: 2em;
    height: 100%;
    
  }

.Downloads{
  display: flex;
  grid-column: 1/-1;
  grid-row: 13/14;
  height: 30vh;
}
  
 

  .News{
    display: flex;
    grid-row: 14/15;
    grid-column: 1/-1;
    justify-content: center;
    align-items: center;
    width: 100%;
  }


  
  .Footer{
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.541);
    color: white;
    grid-row: 15/17;
    grid-column: 1/-1;
    
    
  }