.Descriptioncontainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #0063a0;
    line-height: 1.4px;
    
    
    
}




.Descriptioncontainer h3, h4 {
    padding: 0px 300px;
    margin: 0;
    color: #0063a0;
    font-size: 20px;
    font-weight: normal;
    
    
    
}

.Textcontainer-description h2{
    display: flex;
    justify-content: center;
    overflow: hidden;
    font-size: 26px;
    padding-top: 40px;
    padding-bottom: 20px;
    color: #0063a0;
    font-family: 'Poppins', sans-serif
    
}

.Textcontainer-description h4{
    font-weight: 300;
   
}



.Textcontainer-description{
    
    line-height: 140%;
    font-size: 20px;
    padding: 20px 60px;
    background-color: white;
    
    
}

.highlight{
    font-weight: bold;
    color: #3264aa;
    font-family: "Questrial", sans-serif;
    font-size: 20px;
    text-shadow: none;
    padding: 0;
    margin: 0;
    

}

.Artikel a{
    text-decoration: none;
}

@media only screen and (max-width: 1024px){
    .Descriptioncontainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: #0063a0;
        line-height: 1.4px;
        
        
    }
    
    
    
    
    .Descriptioncontainer h3, h4 {
        padding: 0px 50px;
        margin: 0;
        color: #0063a0;
        font-size: 20px;
        font-weight: normal;
        
        
        
    }
    
    .Textcontainer-description h2{
        display: flex;
        justify-content: center;
        overflow: hidden;
        font-size: 26px;
        padding-top: 30px;
        margin-bottom: 20px;
        color: #0063a0;
        font-family: 'Poppins', sans-serif
        
    }
    
    .Textcontainer-description h4{
        font-weight: 300;
       
    }
    
    
    
    .Textcontainer-description{
        
        line-height: 140%;
        font-size: 20px;
        padding: 20px 40px;
        background-color: white;
        
        
    }
    
    .highlight{
        font-weight: bold;
        color: #3264aa;
        font-family: "Questrial", sans-serif;
        font-size: 20px;
        text-shadow: none;
        padding: 0;
        margin: 0;
        
    

}

}



@media only screen and (max-width: 750px) {
    .Descriptioncontainer h3, h4 {
        padding: 10px 10px;
        margin: 0;
        
    }

}