

.FooterContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    width: 100vw;
    

    background-color: white;
    
    
}

.FooterHeader{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    font-family: "Poppins";
    padding-bottom: 20px;
    color: #3264aa;
    border-bottom:  1.7px solid rgba(0,0,0,0.2);
    width: 100vw;
}


.Datenschutz{
    padding: 10px 10px;
}

.Impressum {
    display: flex;
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-family: "Questrial";
    color: #3264aa;
    background-color: aliceblue;
    width: 100vw;
    
    
}

.Impressum button{
    text-decoration: none;
    color: #3264aa;
    cursor: pointer;
    padding: 10px;
}


.Logo{
    display: flex;
    align-items: center;
    justify-content: center;
   
    
    background-color: transparent;
}

 

.Link{
    
    font-size: 12px;   
}

.Icon {
    color: grey;
}

.FooterItems{
    display: flex;
    color: black;
    letter-spacing: 1.2px;
    padding: 10px 0px;
    font-size: 20px;
    
    
}

.FooterItems a{
    text-decoration: none;
    color: #0063a0;
}


.FooterItems ul{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    list-style-type: none;
    font-family: "Questrial", sans-serif;
    padding: 0 60px;
    color: #0063a0;
    line-height: 140%;
    font-family: "Questrial";
    font-weight: normal;
}


.Footerlinks li a{
   
    list-style-type: none;
    text-decoration: none;
    transition: ease-in-out 0.3s;
    cursor: pointer;
    
}



.Footerlinks li a:hover{
    color: grey;
    text-shadow: 1px 1px black;
    letter-spacing: 1.2px;
    list-style-type: none;
    text-decoration: none;
    transition: ease-in-out 0.3s;
}

.social-media li a{
    color: black;
    list-style-type: none;
    text-decoration: none;
    transition: ease-in-out 0.3s;
}

.social-media li a:hover{
    color: grey;
    text-shadow: 1px 1px black;
    letter-spacing: 1.2px;
    list-style-type: none;
    text-decoration: none;
    transition: ease-in-out 0.3s;
}


.contact-details a{
    text-decoration: none;
    color: #0063a0;
}


.Datenschutzlink{
    margin-left: 20px;
    color: #0063a0;
    font-family: "Questrial";
    font-weight: bold;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 15px;
    
}



.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #0063a0;
    overflow-y: hidden; /* enable scrolling within the overlay component */
    
    
  }
  
  .privacy-policy {
    background-color: white;
    padding: 50px;
    text-align: center;
    
    
  }

  .privacy-policy h4{
    padding: 15px 0px;
  }



  .Datenschutz-downloads{
    display: flex;
    flex-direction: column;
    
  }

  .Datenschutz-downloads a{
    text-decoration: none;
    color: #0063a0;
  }


  .Datenschutzbutton{
    font-size: 15px;
    font-weight: bold;
    
    letter-spacing: 1px;
    background-color: rgba(0, 147, 135, 1);
    border: none;
    color: white;
    padding: 10px 15px;
    cursor: pointer;
    transition: ease-in-out 0.3s;
    box-shadow: 1px 1px 1px  rgba(24, 24, 24, 0.137);
    text-decoration: none;
  }
  .Datenschutzbutton:hover{
    background-color: rgb(32, 197, 183)
  }

@media screen  and (max-width: 1024px) {

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 80vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #0063a0;
        overflow-y: hidden; /* enable scrolling within the overlay component */
        
        
      }

    .FooterHeader{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        font-family: "Poppins";
        color: #3264aa;
        border-bottom:  1.7px solid rgba(0,0,0,0.2);
        width: 100vw;
    }
    
    .FooterContainer{        
        display: flex;
        
        justify-content: flex-start;
        position: absolute        ;
        padding-top: 20px;
        margin: 0;
        width: 100vw;
        height: 30%;
        margin: 0;
        background-color: aliceblue;
       
        
    }

    .FooterItems{
        display: flex;
        
        
        margin: 0;
        
        
    }

    .FooterItems ul{
        display: flex;
        
        align-items: center;
        margin: 0;
        
        list-style-type: none;
        padding: 20px;
        font-size: 14px;
        font-family: 'Courier New', Courier, monospace;
    }

    .Footerlinks li a{
        display: none;
        color: black;
        list-style-type: none;
        text-decoration: none;
        transition: ease-in-out 0.3s;
    }
    
    
}


@media only screen and (max-width: 750px) {

    .FooterContainer{
        display: flex;
        height: fit-content;
        justify-content: flex-end;
        padding-top: 20px;
    }

    .FooterHeader{
        display: flex;
        justify-content: center;
        font-size: 26px;
        font-family: "Poppins";
        color: #3264aa;
        border-bottom:  1.7px solid rgba(0,0,0,0.2);
        width: 100vw;
        max-height: 10vh;
    }

    
    .FooterItems{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height:20vh ;

        width: 100vw;
        

        
        
        
    }

    .FooterHeader{
            display: flex;
            height: 100%;
            overflow: hidden;
        
    }

    .FooterItems ul{
        display: flex;
        
        align-items: center;
        margin: 0;
        
        list-style-type: none;
        padding: 2px;
        font-size: 10px;
        
        font-family: 'Courier New', Courier, monospace;
    }

    .FooterItems ul li{
        overflow: hidden;
        
    }

    .FooterItems ul  a{
        overflow: hidden;
        
    }

    .Impressum {
        display: flex;
        flex-direction: column;
        width: 100vw;
        min-height: 5vh;
        overflow: hidden;
        font-size: 12px;
    }
}


