.Newscontainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    
    text-align: center;
}

.Newsheader {
    display: flex;
        justify-content: center; 
    color: #3264aa;
    width: 100vw;
    
    
    
    
  
    border-bottom:  1.7px solid rgba(0,0,0,0.2);
   
}


.bold{
    font-weight: bold;
}

.Quote {
    font-weight: bold;
    padding-bottom: 5px;
}

.Newslink{
    text-decoration: none;
    color: #3264aa;
}

.Newsheader h1{
    color: #3264aa;
    font-size: 26px;
    font-weight: 600;
    padding: 20px 10px;
    font-family: "Poppins";

    margin: 0;
}

.Newstextcontainer{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 80%;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: white;
    color: #3264aa;
    padding: 20px 300px;
    line-height: 140%;
}

.Newstextcontainer h2{
    padding: 40px 0px;
}

.PdfPreview{
    margin: 20px 0px;
    box-shadow: 1.2px 1px rgba(34, 34, 34, 0.205);
    
    
}

@media screen  and (max-width: 1024px) {
    .Newstextcontainer{
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        background-color: white;
        color: #3264aa;
        padding: 100px 300px;
        line-height: 140%;
    }
    
}