.About{
    display: flex;
    flex-direction: column;
    margin: 0px;
    width: 100vw;
    height: 100%;
    padding-top: 50px;
    background-color: white;
    
    
   
}

.Aboutlinks{
    text-decoration: none;
}




.Aboutheader{
    
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    color: #3264aa;
    flex-direction: column;
    background-color: white;
    padding: 0px 300px;

   
    
}

.Aboutheader h1{
    letter-spacing: 1.2px;
    font-size: 26px;
    padding-bottom: 10px;
    margin-bottom: 40px;
    font-family: "Poppins" , sans-serif;
    overflow: hidden;
    border-bottom:  1.7px solid rgba(0,0,0,0.2);
    width: 100vw;
}

.Aboutheader h3{
    padding-bottom: 20px;
    color: #0063A0;
    margin: 0;
    font-size: 20px ;
    text-shadow: none;
    overflow: hidden;
    line-height: 140%;
}



.Aboutheader p {
    display: flex;
    font-size: 20px;
    
    padding: 0px 0px;
    margin: 0;
    line-height: 1.2;
    height: 100%;
}


.Artikel{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    line-height: 200%;
    
    background-color: white;
    height: 140%;
    width: 100%;
    margin: 0;
    padding: 0px 0px;

    
}

.Artikel h1{
    color: #0063A0;
    letter-spacing: 1.2px;
    font-size: 26px;
    padding-bottom: 10px;
    font-family: "Poppins" , sans-serif;
    overflow: hidden;
    border-bottom:  1.7px solid rgba(0,0,0,0.2);
    width: 100vw;
    

}

.Artikel h3{
    display: flex;
    padding: 0px 20px;
    justify-content: center;
    

}



.Artikel p {
    margin: 0;
    font-size: 20px;
    color: #0063A0;
    cursor: pointer;
    padding: 10px;
}

.highlight{
    font-weight: bold;
}


.Patente{
    display: flex;
    font-size: 20px;
    line-height: 150%;
    justify-content: center;
    font-weight: bold;
    align-items: center;
    height: 30vh;
    padding: 0;
    margin: 0;
    
    
}

.Patente h1{
    font-size: 26px;
}

.Patentcontainer{
   display: flex;
   flex-direction:  column;
    

}

.Patentcontainer a{
    color: #0063A0;
    text-decoration: none;
    font-family: "Questrial";
    font-size: 20px;
    transition:  0.2s ease-in-out;
}

.Patentcontainer a:hover{
    color: rgba(0, 147, 135, 1);
    transition:  0.3s ease-in-out;
}


@media only screen and (max-width: 1024px) {
    .About{
        height: 100vh;
        
    }

    .Aboutheader{
        display: flex;
        padding: 0 50px;
        height: 100vh;
        width: 100%;
    }

    .Aboutheader p {
        padding-bottom: 50px;
    }
    

    .Patente{
        display: flex;
        font-size: 20px;
        line-height: 100%;
        justify-content: center;
        font-weight: bold;
        align-items: center;
        height: 20vh;
        padding:20px;
        margin: 10px 0px;
        
        
    }

    .Artikel{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        line-height: 200%;
        
        background-color: white;
        height: 140%;
        width: 100vw;
    
        
    }
    
    .Artikel h1{
        color: #0063A0;
        letter-spacing: 1.2px;
        font-size: 26px;
        padding-bottom: 10px;
        font-family: "Poppins" , sans-serif;
        overflow: hidden;
        border-bottom:  1.7px solid rgba(0,0,0,0.2);
        width: 100vw;
        
        
    
    }
    
    .Artikel h3{
        display: flex;
        padding: 0px 60px;
        justify-content: center;
        
    
    }
    
    
    
    .Artikel p {
        margin: 0;
        font-size: 20px;
        color: #0063A0;
        cursor: pointer;
        padding: 20px 90px;
    }

}

@media only screen and (max-width: 750px) {
    .About{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        
    }

    .Aboutheader{
        height: 100%;
        
        
    }

    .Aboutheader h1{
        padding-bottom: 20px;
       }
    
   .Aboutheader h3{
    height: 40%;
    padding: 0;
   }

    

    
    .Aboutheader p {
        display: flex;
        height: 100%;
        
        
        
    }
    

    .Patente{
        display: flex;
        font-size: 20px;
        margin: 20px 0px;
        padding:  60px 20px;
        justify-content: center;
        font-weight: bold;
        align-items: center;
        height: 100%;
        overflow: hidden;
    }

    .Patentcontainer a{
        display: flex;
        flex-direction: column;
        
        height: 200%;
        padding: 10px;
        
    }

    .Artikel{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        line-height: 200%;
        
        background-color: white;
        height: 140%;
        width: 100vw;
    
        
    }
    
    .Artikel h1{
        color: #0063A0;
        letter-spacing: 1.2px;
        font-size: 26px;
        padding-bottom: 10px;
        font-family: "Poppins" , sans-serif;
        overflow: hidden;
        border-bottom:  1.7px solid rgba(0,0,0,0.2);
        width: 100vw;
        
        
    
    }
    
    .Artikel h3{
        display: flex;
        padding: 0px 20px;
        justify-content: center;
        
    
    }
    
    
    
    .Artikel p {
        margin: 0;
        font-size: 20px;
        color: #0063A0;
        cursor: pointer;
        padding: 20px 50px;
    }

}