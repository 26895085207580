.DownloadsContainer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100vw;
    padding: 0;
    margin: 0;
}

.DownloadIconContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.DownloadIconContainer p {
    padding-top: 10px;
    font-size: 20px;
    color: white;
    
}

.DownloadIcon {
    padding: 0px 60px;
    max-width: 50px;
}


@media only screen and (max-width: 1024px){

    .DownloadsContainer{
        
    }

    .DownloadIcon{
        max-width: 50px;
        padding-bottom: 20px;
    }
}

@media only screen and (max-width: 750px) {
    
   
    .DownloadIcon{
        max-width: 30px;
        padding-bottom: 20px;
    }

    .DownloadIconContainer p{
        display: flex;
        text-align: center;
        font-size: 12px;
        width: 100%;
    }
    .DownloadIcon {
        padding: 0px 0px;
        max-width: 50px;
    }
}