.CTA{
    display: flex;
    justify-content: space-around;
    color: white; 
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.514);
    height: 80px;
    background: linear-gradient(to top, #e1f1ff2a 0%, #c0dffe75 100%);
    
   
}

.CTA h2{
    
    letter-spacing: 1.1px;
    font-size: 26px;
    font-family: 'Poppins', sans-serif
}

.button{
    font-size: 16px;
    font-weight: bold;
    color: white;
    letter-spacing: 1px;
    background-color: rgba(0, 147, 135, 1);
    border: none;
    
    padding: 10px 15px;
    cursor: pointer;
    transition: ease-in-out 0.3s;
    box-shadow: 1px 1px 1px  rgba(24, 24, 24, 0.137);
    text-decoration: none;

}

.button a{
    text-decoration: none;
    color: white;
}

.button:hover{
    background-color: rgb(32, 197, 183)
    
}

@media only screen and (max-width: 1024px) {
    .CTA h2{
        font-size: 20px;
    }

    .button {
        font-size: 16px;
    }
}

@media only screen and (max-width: 750px) {
    .CTA h2{
        font-size: 14px;
        width: 50%;
        margin-left: 20px;
    }

    .button {
        font-size: 14px;
        margin-right: 20px;
    }

    .button:hover{
        font-size: 14px;
    }
}